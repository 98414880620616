<template>
  <div>
    <header-top-dashboard primary>
      <v-row class="mt-0">
        <v-col
          v-for="(item, i) in miniCards"
          :key="i"
          cols="12"
          lg="3"
          md="6"
          class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row class="mt-0">
        <v-col lg="8" class="pt-0">
          <v-card class="card-shadow bg-default">
            <div class="card-header-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-light text-uppercase text-h6 ls-1 mb-1"
                  >
                    Overview
                  </div>
                  <p class="font-weight-600 text-h3 text-white mb-0">
                    Sales Value
                  </p>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    class="me-4 font-weight-600 text-capitalize btn-neutral"
                    :class="{ 'active-status': bigLineChart.activeIndex === 0 }"
                    @click.prevent="initBigChart(0)"
                    color="#fff"
                    >Month
                  </v-btn>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-neutral"
                    :class="{ 'active-status': bigLineChart.activeIndex === 1 }"
                    @click.prevent="initBigChart(1)"
                    color="#fff"
                    >Week
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="salesChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" class="pt-0">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Performance
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Total Orders</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="ordersChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col lg="4" class="pt-6">
          <members-card></members-card>
        </v-col>
        <v-col lg="4" class="pt-6">
          <to-do-list></to-do-list>
        </v-col>
        <v-col lg="4" class="pt-6">
          <progress-track></progress-track>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col lg="5" class="pt-6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Activity Feed
              </p>
            </div>

            <v-list class="py-0 card-padding">
              <v-list-item class="px-0 py-4">
                <v-list-item-avatar rounded :size="48" class="my-0 me-5">
                  <v-img
                    alt="activity avatar"
                    src="@/assets/img/team-1.jpg"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="text-typo text-h4 font-weight-600 mb-2"
                    >John Snow</v-list-item-title
                  >
                  <small class="text-muted"> 3 days ago </small>
                </v-list-item-content>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  class="font-weight-600 text-capitalize btn-primary bg-primary rounded-sm"
                  small
                >
                  <v-icon class="me-3">ni-fat-add</v-icon> Follow
                </v-btn>
              </v-list-item>
            </v-list>
            <div class="card-border-bottom"></div>
            <v-card-text class="card-padding">
              <p
                class="mb-6 text-body ls-0 line-height-lg font-weight-light font-size-root"
              >
                Personal profiles are the perfect way for you to grab their
                attention and persuade recruiters to continue reading your CV
                because you’re telling them from the off exactly why they should
                hire you.
              </p>
              <v-img
                height="245"
                class="rounded"
                alt="activity comment"
                src="@/assets/img/img-1-1000x600.jpg"
              ></v-img>
              <v-row class="my-4 pb-4 border-bottom">
                <v-col class="py-0">
                  <v-btn
                    v-for="btns in socialActivity"
                    :key="btns.icon"
                    color="transparent elevation-0"
                    class="px-0"
                    :ripple="false"
                  >
                    <v-icon class="me-2" :class="`text-${btns.color}`">{{
                      btns.icon
                    }}</v-icon>
                    <span class="font-weight-600 text-muted">{{
                      btns.text
                    }}</span>
                  </v-btn>
                </v-col>
                <v-col class="d-flex align-center justify-end py-0">
                  <span class="avatar-group d-flex me-2">
                    <v-tooltip
                      v-for="avatar in avatarsActivity"
                      :key="avatar.imgID"
                      top
                      color="#212529"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="24" v-bind="attrs" v-on="on">
                          <img
                            :src="avatar.imgSrc"
                            :alt="`team${avatar.imgID}`"
                          />
                        </v-avatar>
                      </template>
                      <span>{{ avatar.tooltipName }}</span>
                    </v-tooltip>
                  </span>

                  <p class="font-weight-600 text-body ma-0">and 30+ more</p>
                </v-col>
              </v-row>

              <media-comment
                v-for="media in mediaComment"
                :key="media.name"
                size="48"
                :imgSrc="media.avatarImg"
              >
                <h5 class="text-h5 text-typo font-weight-600 mb-2">
                  {{ media.name }}
                </h5>
                <p class="font-weight-light text-body line-height-md">
                  {{ media.description }}
                </p>
                <div class="icon-actions">
                  <v-btn
                    color="transparent elevation-0 me-4"
                    class="px-0"
                    :ripple="false"
                  >
                    <v-icon class="me-2 text-primary">{{
                      media.likeBtnIcon
                    }}</v-icon>
                    <span class="font-weight-600 text-muted text-capitalize">{{
                      media.likeBtnText
                    }}</span>
                  </v-btn>

                  <v-btn
                    color="transparent elevation-0 me-4"
                    class="px-0"
                    :ripple="false"
                  >
                    <v-icon class="me-2 text-dark">{{
                      media.shareBtnIcon
                    }}</v-icon>
                    <span class="font-weight-600 text-muted text-capitalize">{{
                      media.shareBtnText
                    }}</span>
                  </v-btn>
                </div>
              </media-comment>
              <v-divider class="my-8 border-bottom-divider"></v-divider>
              <v-list class="py-0">
                <v-list-item class="px-0">
                  <v-list-item-avatar :size="58" class="my-0 me-5">
                    <v-img alt="team-3" src="@/assets/img/team-3.jpg"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-textarea
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light"
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      name="input-7-4"
                      placeholder="Write your comment"
                    ></v-textarea>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="7" class="pt-6">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Light table</p>
            </div>

            <v-card-text class="px-0 py-0">
              <v-simple-table class="table">
                <template v-slot:default>
                  <thead class="thead-light">
                    <tr>
                      <th class="text-left ps-6">
                        Project
                        <v-icon class="ms-1 text-muted" size="12"
                          >fas fa-sort</v-icon
                        >
                      </th>
                      <th class="text-left ps-6 d-flex align-center">
                        Budget
                        <v-icon class="ms-1 text-muted" size="12"
                          >fas fa-sort</v-icon
                        >
                      </th>
                      <th class="text-left">
                        Status
                        <v-icon class="ms-1 text-muted" size="12"
                          >fas fa-sort</v-icon
                        >
                      </th>
                      <th class="text-left">Users</th>
                      <th class="text-left d-flex align-center">
                        Completion
                        <v-icon class="ms-1 text-muted" size="12"
                          >fas fa-sort</v-icon
                        >
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in projects" :key="item.id">
                      <td class="border-bottom">
                        <v-list class="py-0">
                          <v-list-item class="px-0 py-4">
                            <v-list-item-avatar
                              rounded
                              :size="48"
                              class="my-0 me-5"
                            >
                              <v-img
                                :alt="item.imgSrc"
                                :src="item.imgSrc"
                              ></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="text-body text-subtitle-2 ls-0 font-weight-600"
                                >{{ item.project }}</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </td>
                      <td class="text-body border-bottom text-h5">
                        {{ item.budget }}
                      </td>
                      <td class="text-body border-bottom text-h5">
                        <div class="d-flex align-center">
                          <span :class="`text-${item.statusColor}`" class="me-1"
                            >●</span
                          >
                          {{ item.status }}
                        </div>
                      </td>
                      <td class="border-bottom">
                        <span class="avatar-group d-flex me-2">
                          <v-tooltip
                            v-for="avatar in projects[i].avatars"
                            :key="avatar.avatarSrc"
                            top
                            color="#212529"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar size="32" v-bind="attrs" v-on="on">
                                <img
                                  :src="avatar.avatarSrc"
                                  :alt="avatar.avatarSrc"
                                />
                              </v-avatar>
                            </template>
                            <span>{{ avatar.tooltipName }}</span>
                          </v-tooltip>
                        </span>
                      </td>
                      <td class="border-bottom">
                        <div class="d-flex align-center">
                          <span class="me-2 text-body text-h5"
                            >{{ item.progressValue }}%</span
                          >
                          <v-progress-linear
                            class="progress-shadow"
                            :value="item.progressValue"
                            rounded
                            background-color="#e9ecef"
                            :color="item.progressColor"
                          ></v-progress-linear>
                        </div>
                      </td>
                      <td class="border-bottom">
                        <v-btn
                          color="transparent elevation-0"
                          class="px-0"
                          :ripple="false"
                        >
                          <v-icon class="text-light">fa-ellipsis-v</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-row>
            <v-col cols="12" md="6">
              <v-card class="card-shadow card-padding bg-gradient-default">
                <v-card-text class="px-0 py-0">
                  <p class="font-weight-600 text-h2 text-white mb-2">
                    <sup class="text-white text-caption ls-0">$</sup>
                    3,300
                  </p>
                  <p class="text-light mb-0">Your current balance</p>
                  <p class="text-light mb-2 text-body-1 ls-0">
                    <span class="text-success font-weight-600">+ 15%</span>
                    ($250)
                  </p>
                  <v-btn
                    elevation="0"
                    small
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-neutral mb-12"
                    color="#fff"
                    block
                    >Add Credit</v-btn
                  >

                  <v-row>
                    <v-col>
                      <p class="text-light">Orders: 60%</p>
                      <v-progress-linear
                        class="progress-shadow"
                        value="60"
                        rounded
                        background-color="#e9ecef"
                        color="#2dce89"
                      ></v-progress-linear>
                    </v-col>
                    <v-col>
                      <p class="text-light">Orders: 40%</p>
                      <v-progress-linear
                        class="progress-shadow"
                        value="60"
                        rounded
                        background-color="#e9ecef"
                        color="#fb6340"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="card-shadow card-padding bg-gradient-danger">
                <v-card-text class="px-0 py-0">
                  <v-row>
                    <v-col>
                      <v-img
                        src="@/assets/img/cards/bitcoin.png"
                        width="48"
                        height="30"
                      ></v-img>
                    </v-col>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        elevation="0"
                        small
                        :ripple="false"
                        class="text-capitalize badge-success"
                        color="#b0eed3"
                        >Active</v-btn
                      >
                    </v-col>
                  </v-row>
                  <span
                    class="font-weight-600 text-h6 text-uppercase text-light ls-2 mt-8 d-block"
                    >Username</span
                  >
                  <div class="text-h1 text-white">@johnsnow</div>

                  <span
                    class="font-weight-600 text-h6 text-uppercase text-light ls-2 mt-8 d-block"
                    >Username</span
                  >
                  <div class="font-weight-600 text-h3 text-white mb-4">
                    John Snow
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col lg="8" class="pt-6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    Page Visits
                  </p>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    elevation="0"
                    small
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-primary"
                    color="#5e72e4"
                    >See All</v-btn
                  >
                </v-col>
              </v-row>
            </div>

            <v-card-text class="px-0 py-0">
              <v-simple-table class="table">
                <template v-slot:default>
                  <thead class="thead-light">
                    <tr>
                      <th class="text-left ps-6">Page Name</th>
                      <th class="text-left ps-6">Visitors</th>
                      <th class="text-left">Unique Users</th>
                      <th class="text-left">Bounce Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, key) in visits" :key="item.pageName">
                      <td
                        class="text-body ps-6 font-weight-600 text-h5"
                        :class="{ 'border-bottom': key + 1 != visits.length }"
                      >
                        {{ item.pageName }}
                      </td>
                      <td
                        class="text-body ps-6 text-h5"
                        :class="{ 'border-bottom': key + 1 != visits.length }"
                      >
                        {{ item.visitors }}
                      </td>
                      <td
                        class="text-body ps-4 text-h5"
                        :class="{ 'border-bottom': key + 1 != visits.length }"
                      >
                        {{ item.uniqueUsers }}
                      </td>
                      <td
                        class="text-body d-flex align-center text-h5"
                        :class="{ 'border-bottom': key + 1 != visits.length }"
                      >
                        <v-icon
                          size="11"
                          :class="`text-${item.bounceColor}`"
                          class="me-3"
                          >{{ item.bounceIcon }}</v-icon
                        >
                        {{ item.bounceRate }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" class="pt-6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    Social Traffic
                  </p>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    elevation="0"
                    small
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-primary"
                    color="#5e72e4"
                    >See All</v-btn
                  >
                </v-col>
              </v-row>
            </div>

            <v-card-text class="px-0 py-0">
              <v-simple-table class="table">
                <template v-slot:default>
                  <thead class="thead-light">
                    <tr>
                      <th class="text-left ps-6">Referral</th>
                      <th class="text-left ps-6">Visitors</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in socialTraffic"
                      :key="item.visitors"
                    >
                      <td
                        class="text-body text-h5 ps-6 font-weight-600"
                        :class="{
                          'border-bottom': key + 1 != socialTraffic.length,
                        }"
                      >
                        {{ item.referral }}
                      </td>
                      <td
                        class="text-body text-h5 d-flex align-center ps-6"
                        :class="{
                          'border-bottom': key + 1 != socialTraffic.length,
                        }"
                      >
                        {{ item.visitors }}
                      </td>
                      <td
                        :class="{
                          'border-bottom': key + 1 != socialTraffic.length,
                        }"
                      >
                        <div class="d-flex align-center">
                          <span class="text-body me-3">{{
                            item.progressValue
                          }}</span>
                          <v-progress-linear
                            class="progress-shadow"
                            :class="item.progressColor"
                            :value="item.progressValue"
                            rounded
                            background-color="#e9ecef"
                          ></v-progress-linear>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import MediaComment from "@/components/MediaComment.vue";
import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import MembersCard from "./Widgets/MembersCard.vue";
import ToDoList from "./Widgets/ToDoList.vue";
import ProgressTrack from "./Widgets/ProgressTrack.vue";
let chart;

export default {
  name: "Dashboard",
  components: {
    HeaderTopDashboard,
    MediaComment,
    MembersCard,
    ToDoList,
    ProgressTrack,
  },
  data: function () {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      miniCards: [
        {
          subtitle: "Total Traffic",
          title: "350,897",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "New Users",
          title: "2,356",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "Sales",
          title: "924",
          gradient: "bg-gradient-success",
          icon: "ni-money-coins",
        },
        {
          subtitle: "Performance",
          title: "49,65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
      socialTraffic: [
        {
          referral: "Facebook",
          visitors: "1,480",
          progressValue: "60%",
          progressColor: "bg-gradient-danger",
        },
        {
          referral: "Facebook",
          visitors: "5,480",
          progressValue: "70%",
          progressColor: "bg-gradient-success",
        },
        {
          referral: "Google",
          visitors: "4,807",
          progressValue: "80%",
          progressColor: "bg-gradient-primary",
        },
        {
          referral: "Instagram",
          visitors: "3,678",
          progressValue: "75%",
          progressColor: "bg-gradient-info",
        },
        {
          referral: "Twitter",
          visitors: "2,645",
          progressValue: "30%",
          progressColor: "bg-gradient-warning",
        },
      ],
      visits: [
        {
          pageName: "/argon/",
          visitors: "4,569",
          uniqueUsers: "340",
          bounceColor: "success",
          bounceIcon: "fas fa-arrow-up",
          bounceRate: "46,53%",
        },
        {
          pageName: "/argon/index.html",
          visitors: "3,985",
          uniqueUsers: "319",
          bounceColor: "warning",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "46,53%",
        },
        {
          pageName: "/argon/charts.html",
          visitors: "3,513",
          uniqueUsers: "294",
          bounceColor: "warning",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "36,49%",
        },
        {
          pageName: "/argon/tables.html",
          visitors: "2,050",
          uniqueUsers: "147",
          bounceColor: "success",
          bounceIcon: "fas fa-arrow-up",
          bounceRate: "50,87%",
        },
        {
          pageName: "/argon/profile.html",
          visitors: "1,795",
          uniqueUsers: "190",
          bounceColor: "danger",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "46,53%",
        },
      ],
      projects: [
        {
          id: 0,
          project: "Argon Design System",
          imgSrc: require("@/assets/img/bootstrap.jpg"),
          budget: "$2500",
          status: "pending",
          statusColor: "warning",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "90",
          progressColor: "#fb6340",
        },
        {
          id: 1,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 2,
          project: "Black Dashboard",
          imgSrc: require("@/assets/img/sketch.jpg"),
          budget: "$3150",
          status: "delayed",
          statusColor: "danger",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "73",
          progressColor: "#f5365c",
        },
        {
          id: 3,
          project: "React Material Dashboard",
          imgSrc: require("@/assets/img/react.jpg"),
          budget: "$4400",
          status: "on schedule",
          statusColor: "info",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "90",
          progressColor: "#11cdef",
        },
        {
          id: 4,
          project: "Vue Paper UI Kit PRO",
          imgSrc: require("@/assets/img/vue.jpg"),
          budget: "$2200",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 5,
          project: "Argon Design System",
          imgSrc: require("@/assets/img/bootstrap.jpg"),
          budget: "$2500",
          status: "pending",
          statusColor: "warning",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "60",
          progressColor: "#fb6340",
        },
        {
          id: 6,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 7,
          project: "Black Dashboard",
          imgSrc: require("@/assets/img/sketch.jpg"),
          budget: "$3150",
          status: "delayed",
          statusColor: "danger",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "73",
          progressColor: "#f5365c",
        },
        {
          id: 8,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
      ],
      mediaComment: [
        {
          name: "Michael Lewis",
          avatarImg: "team-1.jpg",
          description:
            "Cras sit amet nibh libero nulla vel metus scelerisque ante sollicitudin. Cras purus odio vestibulum in vulputate viverra turpis.",
          likeBtnIcon: "ni-like-2",
          likeBtnText: "3 Likes",
          shareBtnIcon: "ni-curved-next",
          shareBtnText: "2 shares",
        },
        {
          name: "Jessica Stones",
          avatarImg: "team-2.jpg",
          description:
            "Cras sit amet nibh libero nulla vel metus scelerisque ante sollicitudin. Cras purus odio vestibulum in vulputate viverra turpis.",
          likeBtnIcon: "ni-like-2",
          likeBtnText: "10 Likes",
          shareBtnIcon: "ni-curved-next",
          shareBtnText: "1 shares",
        },
      ],
      avatarsActivity: [
        {
          imgID: 1,
          imgSrc: require("@/assets/img/team-1.jpg"),
          tooltipName: "Michael Lewis",
        },
        {
          imgID: 2,
          imgSrc: require("@/assets/img/team-2.jpg"),
          tooltipName: "Audrey Love",
        },
        {
          imgID: 3,
          imgSrc: require("@/assets/img/team-3.jpg"),
          tooltipName: "Jessica Rowland",
        },
      ],
      socialActivity: [
        {
          icon: "ni-like-2",
          text: "150",
          color: "primary",
        },
        {
          icon: "ni-chat-round",
          text: "36",
          color: "dark",
        },
        {
          icon: "ni-curved-next",
          text: "12",
          color: "dark",
        },
      ],
    };
  },
  methods: {
    oneActive() {
      this.firstBtnActive = true;
      this.secondBtnActive = !this.firstBtnActive;
    },
    twoActive() {
      this.secondBtnActive = true;
      this.firstBtnActive = !this.secondBtnActive;
    },
    initBigChart(index) {
      chart.update(
        (chart.config.data.datasets[0].data = this.bigLineChart.allData[index])
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    chart = new Chart(
      document.getElementById(this.salesChartID).getContext("2d"),
      {
        type: "line",
        data: {
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Performance",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "#5e72e4",
              pointRadius: 0,
              backgroundColor: "transparent",
              barPercentage: 1.6,
              data: this.bigLineChart.allData[0],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            y: {
              grid: {
                color: "rgba(255, 255, 255, .2)",
                drawBorder: false,
                offset: true,
                borderDash: [2],
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: "Open Sans",
              },
            },

            x: {
              grid: {
                color: "transparent",
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: "Open Sans",
              },
            },
          },
          layout: {
            padding: 0,
          },
        },
      }
    );
    ordersChart.createChart(this.ordersChartID);
  },
};
</script>
